import React, { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Container, Row, Col, Button, Spinner, Alert } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { settings } from '../../../config/settings';
import { LanguageProvider } from '../../../core/language-provider';
import { DataManager } from '../../../core/data-manager';
import { ViewManager } from '../../../core/view-manager';
import { AuthorizationManager } from '../../../core/authorization-manager';
import DataGrid from '../../../components/Entity/DataGrid';
import PaginationBlock from '../../../components/Entity/Pagination';
import SearchPro from '../../../components/Entity/SearchPro';
import StandardViewHookTop from '../../../config/hooks/StandardViewTopHook';
import StandardViewHookBottom from '../../../config/hooks/StandardViewBottomHook';
import { Utils } from '../../../core/utils';
import _ from "lodash";


export default function StandardView({ entity }) {

    const [loading, setLoading] = useState(false);
    const [data, setData] = useState(null);
    const [totalPages, setTotalPages] = useState('');
    const [props, setProps] = useState(null);
    const [errors, setErrors] = useState('');
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const [q, setQ] = useState(searchParams.get("q") || null);
    const searchableFields = Utils.cloneObject(ViewManager.getSearchableColumns(entity));
    const [searchObj, setSearchObj] = useState({
        'query': '',
        'fields': searchableFields,
        'constraints': []
    });
    const [params, setParams] = useState(null);
    /* defaults */
    const defaultLimit = (settings.configEntity && settings.configEntity[entity]) ? settings.configEntity[entity].limit : settings.config.limit;
    const defaultSortingField = (settings.configEntity && settings.configEntity[entity]) ? settings.configEntity[entity].sortingField : settings.config.sortingField;
    const defaultSortingOrder = (settings.configEntity && settings.configEntity[entity]) ? settings.configEntity[entity].sortingOrder : settings.config.sortingOrder;



    useEffect(() => {
        initParams()

        return () => {
            console.log("--- ENTITY UNMOUNTED " + entity + " ---")
            setProps(null)
        }
    }, [entity]);

    useEffect(() => {
        if (params !== null) {
            document.documentElement.scrollTo(0, 0);
            var pp = { q: btoa(JSON.stringify(params)) }
            if (searchParams.get("q") !== btoa(JSON.stringify(params))) {
                setSearchParams(pp)
            }
            getData()
        }
    }, [params]);

    useEffect(() => {
        if (params !== null) {
            /*
            setParams({
                ...params, 
                page: 1,
                constraints: searchObj.constraints,
                query: searchObj.query
            });
            */
        }
    }, [searchObj]);


    function initParams() {
        if (q !== null) {
            var queries = JSON.parse(atob(q))
            var p = {
                'page': parseInt(queries.page) || 1,
                'limit': parseInt(queries.limit) || defaultLimit,
                'sortingField': queries.sortingField || defaultSortingField,
                'sortingOrder': queries.sortingOrder || defaultSortingOrder,
                'constraints': queries.constraints || [],
                'query': queries.query || ''
            }

            setSearchObj({
                'query': queries.query || '',
                'fields': searchableFields,
                'constraints': queries.constraints || []
            })
        }
        else {
            var p = {
                'page': 1,
                'limit': defaultLimit,
                'sortingField': defaultSortingField,
                'sortingOrder': defaultSortingOrder,
                'constraints': [],
                'query': ''
            }

            setSearchObj({
                'query': '',
                'fields': searchableFields,
                'constraints': []
            })
        }
        setParams(p)

    }

    async function getData() {
        console.warn('getting data', params)
        setLoading(true)

        var sorting = {
            'field': params.sortingField,
            'order': params.sortingOrder
        }
        if (params.query === '' && (params.constraints.length === 0)) {
            var response = await DataManager.getItems(entity, params.page, params.limit, sorting)
        }
        else {
            let query = params.query || ''
            let fields = searchObj.fields || []
            let constraints = params.constraints || []
            var response = await DataManager.searchItems(entity, params.page, params.limit, sorting, query, fields, constraints)
        }
        if (response.success === 1) {
            console.warn(response)
            setData(response.body)
            setProps(response.table)
            setTotalPages(response.pages)
        }
        else {
            setErrors(response.message)
        }
        setLoading(false)
    }

    function changeSorting(field, order) {
        setParams({
            ...params,
            sortingField: field,
            sortingOrder: order,
            page: 1
        });
    }

    function changeLimit(l) {
        setParams({
            ...params,
            limit: l,
            page: 1
        });
    }

    function changePage(p) {
        setParams({
            ...params,
            page: p
        });
    }

    function search(obj) {
        if (_.isEqual(obj, searchObj)) {
            // oggetti uguali non triggero la ricerca
        }
        else {
            setSearchObj(obj)
            setParams({
                ...params,
                page: 1,
                constraints: obj.constraints,
                query: obj.query
            });
        }
    }

    function create() {
        if (props.reference_entity) {
            navigate('/entity/' + props.reference_entity + '/add')
        }
        else {
            navigate('/entity/' + entity + '/add')
        }
    }




    if (entity !== null && props !== null && data !== null) {
        return (
            <>
                {/* hook */}
                <StandardViewHookTop entity={entity} data={data} searchObj={searchObj} setSearchObj={setSearchObj} />


                <Container fluid className='mt-2 mb-1'>
                    {AuthorizationManager.checkEntityActionAuth(entity, 'create') !== false ? (
                        <Row className='align-items-center'>
                            <Col md={4} xs={12}>
                                <Button variant="outline-success" onClick={() => create()}><FontAwesomeIcon icon="plus" /> {LanguageProvider.get('defaults.create', 'Create')}</Button>
                            </Col>
                            <Col md={8} xs={12}>
                                <SearchPro entity={entity} setSearchObj={search} searchObj={searchObj} />
                            </Col>
                        </Row>
                    ) : (
                        <SearchPro entity={entity} setSearchObj={search} searchObj={searchObj} />
                    )}
                </Container>

                <DataGrid entity={entity} props={props} data={data} setData={setData} params={params} searchObj={searchObj} setSearchObj={setSearchObj} changeSorting={changeSorting} refreshData={getData} loading={loading} />
 
                <PaginationBlock page={params.page} totalPages={totalPages} limit={params.limit} setLimit={changeLimit} changePage={changePage} />


                {/* hook */}
                <StandardViewHookBottom entity={entity} data={data} searchObj={searchObj} setSearchObj={setSearchObj} />
            </>
        );
    }
    else {
        return (
            <Container fluid='md' className='mt-5 mb-5'>
                <div className='text-center p-5'>
                    {errors.length > 0 ? (
                        <Alert variant='danger'>
                            {errors}
                        </Alert>
                    ) : (
                        <>
                            <Spinner animation="border" variant="primary" />
                            <p className='text-primary'>{LanguageProvider.get('defaults.loading')}</p>
                        </>
                    )}
                </div>
            </Container>
        )
    }

};