export const entity_language = {
    entity: {
        user: 'Utenti',
        user_group: 'Gruppi',
        user_entity_saved_filters: 'Filtri salvati',
        user_entity_preferences: 'Preferenze entità',
        blog: 'Blog',
        category: 'Categorie',
        topic: 'Topic',
        db_query: 'Database query'
    },
    labels: {
        "user": {
            "id": 'id',
            "date": 'Data',
            "lastUpdate": 'Ultimo aggiornamento',
            "deleted": 'Eliminato',
            "idUserGroup": 'Gruppo',
            "name": 'Nome',
            "surname": 'Cognome',
            "username": 'Username',
            "email": 'Email',
            "level": 'Livello utente',
            "avatar": 'Avatar',
            "password": 'Password',
            "address": 'Indirizzo'
        },
        "user_group": {
            "id": 'id',
            "date": 'Data',
            "lastUpdate": 'Ultimo aggiornamento',
            "deleted": 'Eliminato',
            "name": 'Nome gruppo',
        },
        "user_entity_saved_filters": {
            "id": 'id',
            "date": 'Data',
            "lastUpdate": 'Ultimo aggiornamento',
            "deleted": 'Eliminato',
            "author": 'Utente',
            "entity": 'Entità',
            "name": 'Nome',
            "notes": 'Note',
            "content": 'Json filtri'
        },
        "user_entity_preferences": {
            "id": 'id',
            "date": 'Data',
            "lastUpdate": 'Ultimo aggiornamento',
            "deleted": 'Eliminato',
            "author": 'Utente',
            "entity": 'Entità',
            "content": 'Json preferenze'
        },
        "db_query": {
            "id": 'id',
            "date": 'Data',
            "lastUpdate": 'Ultimo aggiornamento',
            "deleted": 'Eliminato',
            "author": 'Utente',
            "name": 'Nome della query',
            "notes": 'Note',
            "content": 'Query'
        },
        "blog": {
            "id": 'id',
            "date": 'Data',
            "lastUpdate": 'Ultimo aggiornamento',
            "deleted": 'Eliminato',
            "author": 'Autore',
            "title": 'Titolo',
            "idCategory": 'Categoria',
            "topics": 'Topic',
            "content": 'Contenuto',
            "tags": 'Tag',
            "public": 'Pubblicato',
            "image": 'Immagine',
            "file": 'File',
            "friendlyUrl": 'Friendly url',
            "metaTitle": 'Metatitle',
            "metaDescription": 'Metadescription',
            "comments": 'Commenti'
        },
        "category": {
            "id": 'id',
            "date": 'Data',
            "lastUpdate": 'Ultimo aggiornamento',
            "deleted": 'Eliminato',
            "title": 'Titolo',
        },
        "topic": {
            "id": 'id',
            "date": 'Data',
            "lastUpdate": 'Ultimo aggiornamento',
            "deleted": 'Eliminato',
            "title": 'Titolo',
        }
    }
};