import React, { useState, useEffect } from 'react';
import { Button, Form, Modal, Table, Dropdown } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LanguageProvider } from '../../../core/language-provider';
import { AuthorizationManager } from '../../../core/authorization-manager';
import FormEntityInstanceCompiler from '../../../components/Entity/FormEntityInstanceCompiler';
import { ViewManager } from '../../../core/view-manager';
import FieldPreview from '../../../components/Entity/FieldPreview';
import { Utils } from '../../../core/utils';


export default function DeepExternalEntityField({ parentEntity, parentItem, externalField, label, field, value, entity, update }) {


    const [showModal, setShowModal] = useState(false)
    const [data, setData] = useState({})
    const [index, setIndex] = useState(null)
    const fields = ViewManager.getVisibleColumns(entity)
    const props = ViewManager.getEntityProperties(entity)
    const propsParentEntity = ViewManager.getEntityProperties(parentEntity)



    function closeModal() {
        setShowModal(false)
        setIndex(null)
        setData({})
    }

    function openModal() {
        setShowModal(true)
    }

    async function saveElement(el) {
        if(value === null) {
            value = []
        }
        if(index !== null) {
            var tempId = value[index].id
            el.id = tempId
            value[index] = el
        }
        else {
            value.push(el)
        }
        update(field, value)
        closeModal()
    }

    function edit(item, j) {
        setData(item)
        setIndex(j)
    }

    function remove(item, j) {
        if (j > -1) {
            value.splice(j, 1);
        }
        update(field, value)
    }

    function clone(item, j) {
        var el = Utils.cloneObject(item)
        delete el.id
        value.push(el)
        update(field, value)
        setIndex(null)
        setData({})
    }

    useEffect(() => {
        if(index !== null) {
            openModal()
        }
    }, [index]);


    return (
        <>
            <Form.Group className="field__deepExternalEntity">
                <Form.Label className='small'>{label}</Form.Label><br />

                {typeof value !== 'undefined' && value!== null && value.length > 0 ? (
                    <Table striped hover responsive className='small'>
                        <thead>
                            <tr>
                                {fields.map((item, i) => {
                                    return (
                                        <th key={i} className='small'>
                                            {LanguageProvider.getLabel(entity, item)}
                                        </th>
                                    )
                                })}
                                <th className='last-td'></th>
                            </tr>
                        </thead>
                        <tbody>
                            {(value).map((item, j) => {
                                return (
                                    <tr key={j}>
                                        {fields.map((key, j) => {
                                            var source_field = propsParentEntity.columns[field].options.source_field
                                            if(key === source_field) {
                                                return (
                                                    <td key={j}>
                                                        {parentItem[propsParentEntity.default_key]}
                                                    </td>
                                                )
                                            }
                                            else {
                                                return (
                                                    <td key={j}>
                                                        <FieldPreview item={item} field={key} props={props} entity={entity} />
                                                    </td>
                                                )
                                            }
                                        })}
                                        <td className='text-end last-td'>
                                            <Dropdown className='position-static'>
                                                <Dropdown.Toggle variant="outline-secondary" data-bs-boundary="viewport" size="sm">
                                                    <FontAwesomeIcon icon="cog" />
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu>
                                                    {item.id ? (
                                                        <>
                                                            {
                                                                {
                                                                'true' : <Dropdown.Item className='small' onClick={() => edit(item, j)}>{LanguageProvider.get('defaults.edit')} <span style={{width:30, textAlign:'center'}} className='ms-3 float-end'><FontAwesomeIcon icon="edit" /></span></Dropdown.Item>,
                                                                'false' : <></>,
                                                                'group' :
                                                                    <>
                                                                        {props.authorization && item[props.authorization.groupField] && (
                                                                            <>
                                                                                {props.columns[props.authorization.groupField].input_type === 'number' ? (
                                                                                    <>
                                                                                        {item[props.authorization.groupField] == JSON.parse(localStorage.getItem('userInfo')).idUserGroup && ( 
                                                                                            <Dropdown.Item className='small' onClick={() => edit(item, j)}>{LanguageProvider.get('defaults.edit')} <span style={{width:30, textAlign:'center'}} className='ms-3 float-end'><FontAwesomeIcon icon="edit" /></span></Dropdown.Item>
                                                                                        )}
                                                                                    </>
                                                                                ) : (
                                                                                    <>
                                                                                        {item[props.authorization.groupField].id == JSON.parse(localStorage.getItem('userInfo')).idUserGroup && ( 
                                                                                            <Dropdown.Item className='small' onClick={() => edit(item, j)}>{LanguageProvider.get('defaults.edit')} <span style={{width:30, textAlign:'center'}} className='ms-3 float-end'><FontAwesomeIcon icon="edit" /></span></Dropdown.Item>
                                                                                        )}
                                                                                    </>
                                                                                )}
                                                                            </>
                                                                        )}
                                                                    </>,
                                                                'user' : 
                                                                    <>
                                                                        {props.authorization && item[props.authorization.userField] && (
                                                                            <>
                                                                                {props.columns[props.authorization.userField].input_type === 'number' ? (
                                                                                    <>
                                                                                        {item[props.authorization.userField] == JSON.parse(localStorage.getItem('userInfo')).id && ( 
                                                                                            <Dropdown.Item className='small' onClick={() => edit(item, j)}>{LanguageProvider.get('defaults.delete')} <span style={{width:30, textAlign:'center'}} className='ms-3 float-end'><FontAwesomeIcon icon="trash" /></span></Dropdown.Item>
                                                                                        )}
                                                                                    </>
                                                                                ) : (
                                                                                    <>
                                                                                        {item[props.authorization.userField].id == JSON.parse(localStorage.getItem('userInfo')).id && ( 
                                                                                            <Dropdown.Item className='small' onClick={() => edit(item, j)}>{LanguageProvider.get('defaults.delete')} <span style={{width:30, textAlign:'center'}} className='ms-3 float-end'><FontAwesomeIcon icon="trash" /></span></Dropdown.Item>
                                                                                        )}
                                                                                    </>
                                                                                )}
                                                                            </>
                                                                        )}
                                                                    </>
                                                                }[AuthorizationManager.checkEntityActionAuth(entity, 'edit')]
                                                            }

                                                            {AuthorizationManager.checkEntityActionAuth(entity, 'create') !== false ? ( 
                                                                <Dropdown.Item className='small' onClick={() => clone(item, j)}>{LanguageProvider.get('defaults.clone')} <span style={{width:30, textAlign:'center'}} className='ms-3 float-end'><FontAwesomeIcon icon="clone" /></span></Dropdown.Item>
                                                            ) : (
                                                                <></>
                                                            )}

                                                            {
                                                                {
                                                                'true': <Dropdown.Item className='small' onClick={() => remove(item, j)}>{LanguageProvider.get('defaults.delete')} <span style={{width:30, textAlign:'center'}} className='ms-3 float-end'><FontAwesomeIcon icon="trash" /></span></Dropdown.Item>,
                                                                'false': <></>,
                                                                'group' : 
                                                                    <>
                                                                        {props.authorization && item[props.authorization.groupField] && (
                                                                            <>
                                                                                {props.columns[props.authorization.groupField].input_type === 'number' ? (
                                                                                    <>
                                                                                        {item[props.authorization.groupField] == JSON.parse(localStorage.getItem('userInfo')).idUserGroup && ( 
                                                                                            <Dropdown.Item className='small' onClick={() => remove(item, j)}>{LanguageProvider.get('defaults.delete')} <span style={{width:30, textAlign:'center'}} className='ms-3 float-end'><FontAwesomeIcon icon="trash" /></span></Dropdown.Item>
                                                                                        )}
                                                                                    </>
                                                                                ) : (
                                                                                    <>
                                                                                        {item[props.authorization.groupField].id == JSON.parse(localStorage.getItem('userInfo')).idUserGroup && ( 
                                                                                            <Dropdown.Item className='small' onClick={() => remove(item, j)}>{LanguageProvider.get('defaults.delete')} <span style={{width:30, textAlign:'center'}} className='ms-3 float-end'><FontAwesomeIcon icon="trash" /></span></Dropdown.Item>
                                                                                        )}
                                                                                    </>
                                                                                )}
                                                                            </>
                                                                        )}
                                                                    </>,
                                                                'user' : 
                                                                    <>
                                                                        {props.authorization && item[props.authorization.userField] && (
                                                                            <>
                                                                                {props.columns[props.authorization.userField].input_type === 'number' ? (
                                                                                    <>
                                                                                        {item[props.authorization.userField] == JSON.parse(localStorage.getItem('userInfo')).id && ( 
                                                                                            <Dropdown.Item className='small' onClick={() => remove(item, j)}>{LanguageProvider.get('defaults.delete')} <span style={{width:30, textAlign:'center'}} className='ms-3 float-end'><FontAwesomeIcon icon="trash" /></span></Dropdown.Item>
                                                                                        )}
                                                                                    </>
                                                                                ) : (
                                                                                    <>
                                                                                        {item[props.authorization.userField].id == JSON.parse(localStorage.getItem('userInfo')).id && ( 
                                                                                            <Dropdown.Item className='small' onClick={() => remove(item, j)}>{LanguageProvider.get('defaults.delete')} <span style={{width:30, textAlign:'center'}} className='ms-3 float-end'><FontAwesomeIcon icon="trash" /></span></Dropdown.Item>
                                                                                        )}
                                                                                    </>
                                                                                )}
                                                                            </>
                                                                        )}
                                                                    </>
                                                                }[AuthorizationManager.checkEntityActionAuth(entity, 'delete')]
                                                            }
                                                        </>
                                                    ) : (
                                                        <>
                                                            <Dropdown.Item className='small' onClick={() => edit(item, j)}>{LanguageProvider.get('defaults.edit')} <span style={{width:30, textAlign:'center'}} className='ms-3 float-end'><FontAwesomeIcon icon="edit" /></span></Dropdown.Item>
                                                            <Dropdown.Item className='small' onClick={() => remove(item, j)}>{LanguageProvider.get('defaults.delete')} <span style={{width:30, textAlign:'center'}} className='ms-3 float-end'><FontAwesomeIcon icon="trash" /></span></Dropdown.Item>
                                                        </>
                                                    )}
                                                    

                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </Table>
                ) : (
                    <></>
                )}


                {
                    {
                    'true' : <Button variant="secondary" size="sm" onClick={() => openModal()}>
                                {LanguageProvider.get('defaults.add')}
                            </Button>,
                    'false' : <Button variant="secondary" size="sm" disabled>
                                {LanguageProvider.get('defaults.add')}
                            </Button>,
                    'group' :<Button variant="secondary" size="sm" onClick={() => openModal()}>
                                {LanguageProvider.get('defaults.add')}
                            </Button>,
                    'user' : <Button variant="secondary" size="sm" onClick={() => openModal()}>
                                {LanguageProvider.get('defaults.add')}
                            </Button>,
                    }[AuthorizationManager.checkEntityActionAuth(entity, 'create')]
                }
                
            </Form.Group>


            <Modal show={showModal} onHide={closeModal} size="xl" scrollable={true} backdrop="static" backdropClassName='backdropped'>
                <Modal.Header closeButton>
                    <Modal.Title>{LanguageProvider.get('defaults.edit')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <FormEntityInstanceCompiler entity={entity} confirmCallback={saveElement} initData={data} />
                </Modal.Body>
            </Modal>
        </>
    )

};