import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Container, Alert, Image, InputGroup, Button } from 'react-bootstrap';
import { LoginSocialFacebook, LoginSocialGoogle } from "reactjs-social-login";
import { ApiManager } from "../../core/api-manager";
import { LoginManager } from '../../core/login-manager';
import googleLogo from "./google.svg";
import facebookLogo from "./facebook.svg";
import loginBg from '../../config/login.jpg';
import { settings } from '../../config/settings';
/*
    in settings:
    "loginSocial": {
        "google_client_id": 'samplesamplesample.apps.googleusercontent.com',
        "facebook_app_id": 'samplesamplesample'
    }
*/


export default function LoginSocial() {

    const navigate = useNavigate()
    const [errors, setErrors] = useState('');


    /* login google */
    const [isGoogleLogging, setIsGoogleLogging] = useState(false);
    const completeLoginWithGoogle = async (res) => {
        setIsGoogleLogging(false);
        console.log("google", res);
        var body = {
            token: res.data.access_token,
        };
        var response = await ApiManager.sendRequest("/auth/google_login", body);
        console.warn("Google Login response: ", response);
        if (response.success === 1) {
            var ala = await LoginManager.afterLoginActions(response);
            if (ala === true) {
                navigate('/')
            }
        }
        else {
            setErrors('Errore durante il login con Google.')
            setTimeout(() => {
                setErrors('')
            }, "5000")
        }
    };

    /* facebook login */
    const [isFacebookLogging, setIsFacebookLogging] = useState(false);
    const completeLoginWithFacebook = async (res) => {
        console.log("facebook", res);
        var body = {
            token: res.data.accessToken
        };
        var response = await ApiManager.sendRequest("/auth/facebook_login", body);
        console.warn("Facebook Login response: ", response);
        if (response.success === 1) {
            var ala = await LoginManager.afterLoginActions(response);
            if (ala === true) {
                navigate('/')
            }
        }
        else {
            setErrors('Errore durante il login con Facebook.')
            setTimeout(() => {
                setErrors('')
            }, "5000")
        }
    };


    return (
        <Container fluid id="login-container" style={{ "backgroundImage": `url('${loginBg}')` }}>
            <div className="form-signin">
                <div className="mb-3 mt-2 text-center">
                    <div id='login-logo'></div>
                    <h3>Login social</h3>
                </div>

                {settings.loginSocial && settings.loginSocial.google_client_id ? (
                    <LoginSocialGoogle
                        client_id={settings.loginSocial.google_client_id}
                        scope={"email profile"}
                        onLoginStart={() => setIsGoogleLogging(true)}
                        onResolve={(res) => completeLoginWithGoogle(res)}
                        onReject={(err) => console.error(err)}
                    >
                        <InputGroup className='w-100 mb-4'>
                            <Button variant='light' size='lg' className='w-25'>
                                <Image src={googleLogo} height={30} />
                            </Button>
                            <Button variant='light' size='lg' className='w-75 text-start'>
                                <span className='text-muted small'>
                                    Login con Google
                                </span>
                            </Button>
                        </InputGroup>
                    </LoginSocialGoogle>
                ) : (
                    <></>
                )}

                {settings.loginSocial && settings.loginSocial.facebook_app_id ? (
                    <LoginSocialFacebook
                        appId={settings.loginSocial.facebook_app_id}
                        onLoginStart={() => setIsFacebookLogging()}
                        onResolve={(res) => completeLoginWithFacebook(res)}
                        onReject={(err) => console.log(err)}
                        isOnlyGetToken={true}
                    >
                        <InputGroup className='w-100 mb-4'>
                            <Button variant='primary' size='lg' className='w-25'>
                                <Image src={facebookLogo} height={30} />
                            </Button>
                            <Button variant='primary' size='lg' className='w-75 text-start'>
                                <span className='text-white small'>
                                    Login con Facebook
                                </span>
                            </Button>
                        </InputGroup>
                    </LoginSocialFacebook>
                ) : (
                    <></>
                )}


                {errors.length > 0 &&
                    <Alert variant='danger'>
                        {errors}
                    </Alert>
                }
                <div className="text-center">
                    <Link to="/">Torna al login</Link>
                </div>
            </div>
        </Container>

    )

};