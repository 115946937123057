import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';
import { Modal, Container, Alert, Spinner, Card, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LanguageProvider } from '../../core/language-provider';
import { DataManager } from '../../core/data-manager';
import { ViewManager } from '../../core/view-manager';
import FormEntityInstanceCompiler from '../../components/Entity/FormEntityInstanceCompiler';


export default function EntityEdit() {

    const [data, setData] = useState(null);
    const [errors, setErrors] = useState('');
    const { entity } = useParams();
    const props = ViewManager.getEntityProperties(entity);
    const navigate = useNavigate();

    const [searchParams, setSearchParams] = useSearchParams();
    const [id, setId] = useState(searchParams.get("id") || null)

    useEffect(() => {
        async function init() {
            var response = await DataManager.getItem(entity, id);
            if(response.success === 1) {
                console.warn(response.body[0])
                setData(response.body[0])
            }
            else {
                setErrors(LanguageProvider.get('errors.get'))
                console.warn(response)
            }
        }
        init()  
    }, []);


    async function saveElement(el) {
        console.log(el)
        var response = await DataManager.updateItem(entity, el, id);
        if(response.success === 1) {
            if(props.callback_entity) {
                navigate('/entity/' + props.callback_entity)
            }
            else {
                navigate('/entity/' + entity)
            }
        }
        else {
            setErrors(LanguageProvider.get('errors.update'))
            console.warn(response)
            setTimeout(() => {
                setErrors('')
            }, "5000")
        }
    }



    const [statusModalGoBack, setStatusModalGoBack] = useState(false)

    function closeModalGoBack() {
        setStatusModalGoBack(false)
    }




    if (data !== null) {
        return (
            <>
                <Container fluid className='mt-5 mb-5'>

                    <h3>{LanguageProvider.get('defaults.edit')} - {LanguageProvider.get('entity.'+entity)}</h3>

                    <Button onClick={() => setStatusModalGoBack(true)} size="sm" variant="outline-secondary" className='mt-3 mb-2'>
                        <FontAwesomeIcon icon="chevron-left" className='me-1' />
                        {LanguageProvider.get('defaults.back')}
                    </Button>

                    <Card className='mt-4'>
                        <Card.Body>
                            <FormEntityInstanceCompiler entity={entity} confirmCallback={saveElement} initData={data} />
                        </Card.Body>
                    </Card>
                    {errors.length > 0 &&
                        <Alert className='mt-4' variant='danger'>
                            {errors}
                        </Alert>
                    }
                </Container>


                <Modal show={statusModalGoBack} onHide={closeModalGoBack} size="md">
                    <Modal.Header closeButton>
                        <Modal.Title>{LanguageProvider.get('defaults.go_back_title')}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>{LanguageProvider.get('defaults.go_back_text')}</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="success" onClick={() => navigate(-1)}>
                            {LanguageProvider.get('defaults.exit')}
                        </Button>
                        <Button variant="secondary" onClick={closeModalGoBack}>
                            {LanguageProvider.get('defaults.close')}
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
        )
    }
    else {
        return (
            <Container fluid='md' className='mt-5 mb-5'>
                {errors.length > 0 &&
                    <Alert variant='danger'>
                        {errors}
                    </Alert>
                }
                <div className='text-center p-5'>
                    <Spinner animation="border" variant="primary" />
                    <p className='text-primary'>{LanguageProvider.get('defaults.loading')}</p>
                </div>
            </Container>
        )
    }
};