export const menu = [
    {
        "type": 'header',
        "href": '#',
        "icon": '',
        "entity": '',
        "label": 'Pagine'
    },
    {
        "type": 'item',
        "href": '/',
        "icon": 'home',
        "entity": '',
        "label": 'Dashboard'
    },
    {
        "type": 'item',
        "href": '/entity/blog',
        "icon": 'blog',
        "entity": 'blog',
        "label": 'Blog'
    },
    {
        "type": 'item',
        "href": '/entity/category',
        "icon": 'folder',
        "entity": 'category',
        "label": 'Categorie'
    },
    {
        "type": 'item',
        "href": '/entity/topic',
        "icon": 'list',
        "entity": 'topic',
        "label": 'Topic'
    },

    {
        "type": 'item',
        "href": '/entity/db_query',
        "icon": 'code',
        "entity": 'db_query',
        "label": 'DB query'
    },

    {
        "type": 'header',
        "href": '#',
        "icon": '',
        "entity": '',
        "label": 'Utente'
    },
    {
        "type": 'item',
        "href": '/settings',
        "icon": 'user-gear',
        "entity": '',
        "label": 'Profilo'
    },
    {
        "type": 'folder',
        "href": '#',
        "icon": 'cogs',
        "entity": '',
        "label": 'Impostazioni',
        "items": [
            {
                "type": 'item',
                "href": '/entity/user',
                "entity": 'user',
                "label": 'Utenti'
            },
            {
                "type": 'item',
                "href": '/entity/user_group',
                "entity": 'user_group',
                "label": 'Gruppi'
            },
            {
                "type": 'item',
                "href": '/entity/user_entity_saved_filters',
                "entity": 'user_entity_saved_filters',
                "label": 'Filtri salvati'
            },
            {
                "type": 'item',
                "href": '/entity/user_entity_preferences',
                "entity": 'user_entity_preferences',
                "label": 'Preferenze entità'
            },
        ]
    },
    {
        "type": 'item',
        "href": '/logout',
        "icon": 'arrow-right-from-bracket',
        "entity": '',
        "label": 'Logout'
    }
]