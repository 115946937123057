import React, { useState, useEffect, useRef } from 'react';
import { Button, Modal, Container, Pagination, Tab, Tabs, Table, Spinner, Row, Col } from "react-bootstrap";
import { LanguageProvider } from '../../../core/language-provider';
import { DataManager } from '../../../core/data-manager';
import { AuthorizationManager } from '../../../core/authorization-manager';
import { ViewManager } from '../../../core/view-manager';
import FormEntityInstanceCompiler from '../../../components/Entity/FormEntityInstanceCompiler';
import FieldPreview from '../../../components/Entity/FieldPreview';
import SearchPro from '../../../components/Entity/SearchPro';
import { Utils } from '../../../core/utils';
import _ from "lodash";
import ModalPickerTopHook from '../../../config/hooks/ModalPickerTopHook';
import { customFilters } from '../../../config/hooks/customFilters';


export default function ModalPicker({ view, selected, entity, closeModalPicker, saveModalPicker, keyPicker, parentEntity, parentItem }) {

    //for external entity

    const modalRef = useRef(null);
    
    const [loading, setLoading] = useState(false);
    const [statusModalPicker, setStatusModalPicker] = useState(false)
    const [pickerData, setPickerData] = useState(null)
    const [page, setPage] = useState(1)
    const [totalPages, setTotalPages] = useState(1)
    const [limit, setLimit] = useState(20)
    const [data, setData] = useState(null)
    const [ids, setIds] = useState([])
    const [activeTab, setActiveTab] = useState('edit')
    const searchableFields = Utils.cloneObject(ViewManager.getSearchableColumns(entity));
    const [searchObj, setSearchObj] = useState({
        'query': '',
        'fields': searchableFields,
        'constraints': []
    });

  
    useEffect(() => {
        if(selected !== null) {
            getData(page, limit)
        }
    }, [page, limit, entity, keyPicker, searchObj]);


    useEffect(() => {
        if(selected === null) {
            setStatusModalPicker(false)
        }
        else {
            setStatusModalPicker(true)
            setPickerData(selected)

            if(selected instanceof Array) {
                if(selected.length > 0) {
                    let arrayIds = arrayOfObjToArrayOfId(selected)
                    setIds(arrayIds)
                }
            }
            else {
                setIds(selected.id)
            }
        }

        return () => {
            setIds([])
        }
    }, [selected]);


    async function getData(page, limit) {
        if(modalRef.current) {
            modalRef.current.scrollTo({
                top: 0,
                left: 0,
                behavior: "instant", // Optional if you want to skip the scrolling animation
            });
        }
        setLoading(true)
        //let sorting = { "field": labelPicker, "order": "asc" }
        var sorting = { "field": "id", "order": "desc" }
        const properties = ViewManager.getEntityProperties(parentEntity);
        const keyProps = properties.columns[keyPicker]
        if(keyProps && keyProps.options && keyProps.options.orderBy) {
            sorting = { "field": keyProps.options.orderBy.field ? keyProps.options.orderBy.field : 'id', "order": keyProps.options.orderBy.order ? keyProps.options.orderBy.order : 'desc' }
        }
        
        var search = searchObj.constraints
        
        var propsParentEntity = ViewManager.getEntityProperties(parentEntity)
        var columnProps = propsParentEntity.columns[keyPicker]
        
        if(columnProps.options && columnProps.options.filters) {
            var filters = columnProps.options.filters
            for (let i = 0; i < filters.length; i++) {
                var filter = filters[i]
                if(filter.operation === 'equalToParentField' && filter.value) {
                    if(parentItem) {
                        var parentItemValue = parentItem

                        var exploded = filter.value.split('.');
                        for (var j = 0; j < exploded.length; j++) {
                            var key = exploded[j];
                            parentItemValue = parentItemValue[key];
                        }

                        if(search.some(item => (item.fieldName === filter.field && item.type === "value" && item.value === parentItemValue || ''))) {
                            //già presente
                        }
                        else {
                            search.push({
                                fieldName: filter.field,
                                type: "value",
                                value: parentItemValue || ''
                            })
                        }
                    }
                }
                if(filter.operation === 'equalToValue' && filter.value) {
                    if(search.some(item => (item.fieldName === filter.field && item.type === "value" && item.value === filter.value || ''))) {
                        //già presente
                    }
                    else {
                        search.push({
                            fieldName: filter.field,
                            type: "value",
                            value: filter.value || ''
                        })
                    }
                }
                if(filter.operation === 'customFilter') {
                    if(customFilters[filter.reference]) {
                        var newFilters = customFilters[filter.reference]
                        for(var k=0; k<newFilters.length; k++) {
                            var newFilter = newFilters[k]
                            if(search.some(item => (item.fieldName === newFilter.fieldName && item.type === newFilter.type && item.value === newFilter.value || ''))) {
                                //già presente
                            }
                            else {
                                search.push(newFilter)
                            }
                        }
                    }
                }
            }
        }

        if(searchObj.query === '' && _.isEmpty(search)) {
            var response = await DataManager.getItems(entity, page, limit, sorting)
        }
        else {
            let query = searchObj.query || ''
            let fields = searchObj.fields || []
            let constraints = search || []
            var response = await DataManager.searchItems(entity, page, limit, sorting, query, fields, constraints)
        }
        if(response.success === 1) {
            setData(response.body);
            setTotalPages(response.pages)
        }
        else {
            console.warn(response)
        }

        setLoading(false)
    }


    function arrayOfObjToArrayOfId(list) {
        var temp = []
        list.map((item, i) => {
            temp.push(item.id)
        });
        return temp
    }

    function addToList(item) {
        if(selected instanceof Array) {
            var temp = Utils.cloneObject(pickerData)
            temp.push(item)
            setPickerData(temp)
    
            var arrayIds = arrayOfObjToArrayOfId(temp)
            setIds(arrayIds)
        }
        else {
            setPickerData(item)
            setIds(item.id)
        }
    }

    function removeFromList(item) {
        if(selected instanceof Array) {
            var temp = []
            for (var i = 0; i < pickerData.length; i++) {
                if (pickerData[i].id !== item.id) {
                    temp.push(pickerData[i])
                }
            }
            setPickerData(temp)
    
            let arrayIds = arrayOfObjToArrayOfId(temp)
            setIds(arrayIds)
        }
        else {
            setPickerData('')
            setIds([])
        }
    }

    function addToListConfirm(item) { //double click
        if(selected instanceof Array) {
            return;
        }
        else {
            setPickerData(item)
            setIds(item.id)

            saveModalPicker(keyPicker, item)
        }
    }

    /* paginations */
    function goToPrevPage() {
        if(page > 1) {
            setPage(page-1)
        }
    }
    function goToNextPage() {
        if(page < totalPages) {
            setPage(page+1)
        }
    }
    function goToFirstPage() {
        if(page !== 1) {
            setPage(1)
        }
    }
    function goToLastPage() {
        if(page !== totalPages) {
            setPage(totalPages)
        }
    }


    function saveModalPickerFun() {
        saveModalPicker(keyPicker, pickerData)
    }

    function closePicker() {
        closeModalPicker()
    }

    function search(obj) {
        if(_.isEqual(obj, searchObj)) {
            // oggetti uguali non triggero la ricerca
        }
        else {
            setPage(1)
            setSearchObj(obj)
        }
    }



    // entity instance compiler
    class FormEntityInstanceCompilerManager{
        static async confirm(data) {
            console.log("Saving Form Entity Compiler...")
            console.log(data)
            console.log(view.properties)
            
            let response = await DataManager.insertItem(entity, data)
            if(response.success === 1) {
                getData(1, limit)
                setActiveTab('edit') // torno alla selezione elementi del picker
            }
            
        }
    }

    const handleKeyPress = (event) => {
        if(document.querySelectorAll('div[role=dialog]').length > 1 || document.activeElement.tagName === 'INPUT' || activeTab !== 'edit') {
            // more than one modal is open so enter could close them all
            // one input (maybe search) is focused
        }
        else {
            if(event.key === 'Enter') {
                console.log('enter press here! Saving modal.')
                saveModalPickerFun()
            }
        }
    }


    if(selected !== null && data !== null) {
        return (
        <>
            <Modal data-entity={entity ? entity : ''} tabIndex={1} onKeyPress={handleKeyPress} show={statusModalPicker} onHide={closePicker} size="xl" scrollable={true} backdrop="static" backdropClassName='backdropped'>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <span className='small text-muted'>{LanguageProvider.get('defaults.select')} -</span> {LanguageProvider.get('entity.'+entity)}
                        {loading === true ? (
                            <span>
                                <Spinner className='ms-3' size='md' animation="border" variant="secondary" />
                            </span>
                        ) : (
                            <span>&nbsp;</span>
                        )}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body style={{minHeight:'400px'}} ref={modalRef}>
                    {/* hook */}
                    <ModalPickerTopHook entity={entity} selected={selected} searchObj={searchObj} setSearchObj={setSearchObj} data={data} />

                    <Tabs
                        activeKey={activeTab}
                        onSelect={(k) => setActiveTab(k)}
                        className="mb-3"
                    >
                        <Tab eventKey="edit" title={LanguageProvider.get('defaults.select')}>

                            <SearchPro entity={entity} setSearchObj={search} autoFocus={true} searchObj={searchObj} />
                            
                            <Table bordered striped hover responsive className='small mt-2'>
                                <thead className='small'>
                                    <tr>
                                        {view.availableFields.map((label, j) => {
                                            var l = LanguageProvider.getLabel(entity, label);
                                            return (
                                                <th key={j} style={{backgroundColor:'#bbb'}}>
                                                    {l}
                                                </th>
                                            )
                                        })}
                                    </tr>
                                </thead>
                                <tbody className='small'>
                                    {data.map((item, i) => {
                                        if((ids instanceof Array && ids.includes(item.id)) || ids === item.id) {
                                            return (
                                                <tr key={i} className='row-selected' onClick={() => removeFromList(item)}>
                                                    {view.availableFields.map((label, j) => {
                                                        return (
                                                            <td key={j} className='bg-gradient' style={{backgroundColor:'rgb(144 212 173)'}}>
                                                                <FieldPreview item={item} field={label} props={view.properties} entity={entity} />
                                                            </td>
                                                        )
                                                    })}
                                                </tr>
                                            )
                                        }
                                        else {
                                            return (
                                                <tr key={i} onClick={() => addToList(item)} onDoubleClick={() => addToListConfirm(item)}>
                                                    {view.availableFields.map((label, j) => {
                                                        return (
                                                            <td key={j}>
                                                                <FieldPreview item={item} field={label} props={view.properties} entity={entity} />
                                                            </td>
                                                        )
                                                    })}
                                                </tr>
                                            )
                                        }
                                    })}
                                </tbody>
                            </Table>
                            
                        

                            <Container className="mt-4 text-center" fluid>
                                <Pagination>
                                    <Pagination.First className={page === 1 ? 'disabled' : ''} onClick={goToFirstPage} />
                                    <Pagination.Prev className={page <= 1 ? 'disabled' : ''} onClick={goToPrevPage} />
                                    <Pagination.Item key={page}>
                                        {page}
                                    </Pagination.Item>
                                    <Pagination.Next className={page >= totalPages ? 'disabled' : ''} onClick={goToNextPage} />
                                    <Pagination.Last className={page >= totalPages ? 'disabled' : ''} onClick={goToLastPage} />
                                </Pagination>
                            </Container>
                        </Tab>
                        
                        {AuthorizationManager.checkEntityActionAuth(entity, 'create') !== false ? (  
                            <Tab eventKey="add" title={LanguageProvider.get('defaults.add')}>
                                <FormEntityInstanceCompiler entity={view.properties.reference_entity ? view.properties.reference_entity : entity} confirmCallback={FormEntityInstanceCompilerManager.confirm} />
                            </Tab>
                        ) : (
                            <></>
                        )}
                        
                    </Tabs>

                </Modal.Body>
                <Modal.Footer>
                    {activeTab === 'edit' ? ( 
                        <Button variant="primary" onClick={saveModalPickerFun}>
                            {LanguageProvider.get('defaults.save')}
                        </Button>
                    ) : (
                        <></>
                    )}
                    <Button variant="secondary" onClick={closePicker}>
                        {LanguageProvider.get('defaults.close')}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
        );
    }
};