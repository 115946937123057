import React, { useEffect, useState } from 'react';
import { Button, Modal, Table, Spinner, Alert } from 'react-bootstrap';
import { ApiManager } from '../../core/api-manager';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


export default function DGLTH_db_query({ item, entity = null }) {

    const useScript = (url, name) => {
        const [lib, setLib] = useState({})
        useEffect(() => {
            const script = document.createElement('script')
    
            script.src = url
            script.async = true
            script.onload = () => setLib({ [name]: window[name] })
    
            document.body.appendChild(script)
    
            return () => {
                document.body.removeChild(script)
            }
        }, [url])
        return lib
    }
    const [statusModal, setStatusModal] = useState(false)
    const [loadingQuery, setLoadingQuery] = useState(false)
    const [data, setData] = useState(null)
    const [errors, setErrors] = useState('')
    const { XLSX } = useScript('//cdn.jsdelivr.net/npm/xlsx/dist/xlsx.full.min.js', 'XLSX')


    function closeModal() {
        setStatusModal(false)
    }

    function openModal() {
        setStatusModal(true)
    }

    async function getQuery() {
        setLoadingQuery(true)
        var body = {
            'query': item.content
        }
        var response = await ApiManager.sendAuthenticatedRequest('/custom/custom_query', body)
        console.warn("response", response)
        setLoadingQuery(false)
        if (response.success === 1) {
            setData(response.body)
        }
        else {
            setErrors(response.message)
            setTimeout(function () {
                setErrors('')
            }, 3000);
        }
    }

    function exportToExcel() {
        if(data !== null) {
            var filename='export-results.xlsx';
            var ws = XLSX.utils.json_to_sheet(data);
            var wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, "Page1");
            XLSX.writeFile(wb,filename);
        }
    }


    return (
        <>
            <Button variant='outline-primary' size='sm' onClick={() => openModal()}>Esegui query</Button>
            {statusModal !== false && (
                <Modal show={statusModal} onHide={closeModal} backdrop="static" keyboard={false} size={'xl'} scrollable>
                    <Modal.Header closeButton>
                        <Modal.Title>Query: {item.name}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <pre>
                            {item.content}
                        </pre>

                        {errors.length > 0 ? (
                            <Alert variant='warning'>
                                {errors}
                            </Alert>
                        ) : (
                            <></>
                        )}

                        {data && data.length ? (
                            <div id="get_query_table_results">
                                <Table responsive className='small' bordered striped>
                                    <thead className='small'>
                                        <tr>
                                            {Object.keys(data[0]).map(header => <th key={header}>{header}</th>)}
                                        </tr>
                                    </thead>
                                    <tbody className='small'>
                                        {(data.map(item => Object.values(item))).map((row, index) => (
                                            <tr key={index}>
                                                {row.map((cell, index) => <td key={index}>{cell}</td>)}
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </div>
                        ) : (
                            <></>
                        )}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={closeModal}>
                            Chiudi finestra
                        </Button>

                        {loadingQuery ? (
                            <Button variant="primary">
                                <Spinner size='sm' /> Elaborazione
                            </Button>
                        ) : (
                            <Button variant="primary" onClick={() => getQuery()}>
                                <FontAwesomeIcon icon="code" className='me-1' /> Esegui query
                            </Button>
                        )}

                        <Button variant="warning" onClick={exportToExcel}>
                            <FontAwesomeIcon icon="download" className='me-1' /> Esporta risultato
                        </Button>
                    </Modal.Footer>
                </Modal>
            )}

        </>
    )
};