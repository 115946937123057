import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Breadcrumb } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LanguageProvider } from '../core/language-provider';
import notfound from '../assets/404.svg';


export default function NotFound() {
    return (
        <Container fluid='md' className='mt-5 mb-5'>
            <Container fluid>
                <Breadcrumb>
                    <Breadcrumb.Item linkProps={{ to: '/' }} linkAs={Link}><FontAwesomeIcon icon="home" /></Breadcrumb.Item>
                    <Breadcrumb.Item linkProps={{ to: '/about' }} linkAs={Link}>{LanguageProvider.get('header.about')}</Breadcrumb.Item>
                    <Breadcrumb.Item active>404</Breadcrumb.Item>
                </Breadcrumb>
            </Container>

            <div className='text-center'>
                <img src={notfound} width={'50%'} />
            </div>

        </Container>
    );
};