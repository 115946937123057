import React, { useState, useEffect } from 'react';
import { Button, Form, Badge, InputGroup } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LanguageProvider } from '../../../core/language-provider';
import { ViewManager } from '../../../core/view-manager';
import { Utils } from '../../../core/utils';
import ModalPicker from '../../../components/Modals/ModalPicker';


export default function MultipleExternalEntity({ parentEntity, parentItem, externalField, label, field, value, entity, update }) {

    const [view, setView] = useState(null)

    useEffect(() => {
        const properties = ViewManager.getEntityProperties(entity);
        const availableFields = Utils.cloneObject(ViewManager.getEditableColumns(entity));
        setView({'availableFields':availableFields, 'properties':properties})
    }, [entity]);


    /* modal picker */
    const [modalPickerData, setModalPickerData] = useState(null)
    const [labelPicker, setLabelPicker] = useState(null)
    const [keyPicker, setKeyPicker] = useState(null)
    const [modalPickerEntity, setModalPickerEntity] = useState(null)

    function closeModalPicker() {
        setModalPickerData(null)
    }
    function openPickModal(data, label, itemEntity, key) {
        if(data === null) {
            data = []
        }
        setLabelPicker(label)
        setModalPickerData(data)
        setModalPickerEntity(itemEntity)
        setKeyPicker(key)
    }
    function saveModalPicker(key, value) {
        if (value !== null) {
            update(key, value)
        }
        setModalPickerData(null)
    }



    if(view !== null) return (
        <>

            <Form.Group className="field__externalEntity multiple">
                <Form.Label className='small'>{label}</Form.Label>
                <br />
                <InputGroup>
                    {typeof value !== 'undefined' && value ? (
                        <InputGroup.Text className="w-75 bg-white" style={{flexFlow: 'wrap'}}> 
                            {(value).map((item, j) => {
                                return (
                                    <Badge className='me-2 mt-1 mb-1' key={j} bg="secondary" style={{maxWidth: '100%', whiteSpace: 'break-spaces'}}>
                                        {item[externalField]}
                                    </Badge>
                                )
                            })}
                        </InputGroup.Text>
                    ) : (
                        <Form.Control className="w-75" disabled />
                    )}
                    <Button className="w-25" variant="secondary" onClick={() => openPickModal(value, externalField, entity, field)}>
                        <FontAwesomeIcon icon="bars" />
                    </Button>
                </InputGroup>
            </Form.Group>


            <ModalPicker parentEntity={parentEntity} parentItem={parentItem} view={view} selected={modalPickerData} entity={modalPickerEntity} keyPicker={keyPicker} labelPicker={labelPicker} saveModalPicker={saveModalPicker} closeModalPicker={closeModalPicker} />
        </>
    )
};