import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Badge, Form, InputGroup, DropdownButton, Dropdown, ButtonGroup, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Select from 'react-select';
import { LanguageProvider } from '../../../core/language-provider';
import { ViewManager } from '../../../core/view-manager';
import { DataManager } from '../../../core/data-manager';
import { Utils } from '../../../core/utils';
import { toast } from 'react-toastify';


export default function SearchPro({ entity, setSearchObj, searchObj = null, autoFocus = false }) {

    const properties = ViewManager.getEntityProperties(entity)
    const availableFields = Utils.cloneObject(ViewManager.getEditableColumns(entity))
    const searchableFields = Utils.cloneObject(ViewManager.getSearchableColumns(entity))
    const [activeFields, setActiveFields] = useState(searchableFields)
    const [constraints, setConstraints] = useState([])
    const [queryString, setQueryString] = useState('')
    const listOfUsableFilter = []
    searchableFields.map(key => {
        listOfUsableFilter.push({
            label: LanguageProvider.getLabel(entity, key),
            key: key,
            value: properties.columns[key]
        });
    });
    const usableFiltersConditions = [
        { value: 'value', label: 'Uguale a' },
        { value: 'different_from_value', label: 'Diverso da' }
    ]
    const usableFiltersConditionsStrings = [
        { value: 'value', label: 'Uguale a' },
        { value: 'like', label: 'Contiene' },
        { value: 'different_from_value', label: 'Diverso da' },
        { value: 'in', label: 'In (digita valori separati da ,)' }
    ]
    const usableFiltersConditionsNumbers = [
        { value: 'value', label: 'Uguale a' },
        { value: 'different_from_value', label: 'Diverso da' },
        { value: 'minor', label: 'Minore o uguale di' },
        { value: 'greater', label: 'Maggior o uguale di' },
        { value: 'in', label: 'In (digita valori separati da ,)' }
    ]
    const usableFiltersConditionsDates = [
        { value: 'value', label: 'Uguale a' },
        { value: 'different_from_value', label: 'Diverso da' },
        { value: 'minor', label: 'Minore o uguale di' },
        { value: 'greater', label: 'Maggior o uguale di' }
    ]
    const usableFiltersConditionsOptions = [
        { value: 'in', label: 'Con valori' }
    ]


    useEffect(() => {
        console.log(properties, availableFields, searchableFields)

        //popolo filtri partendo da params
        if (searchObj !== null) {
            if (searchObj.query && searchObj.query.length > 0) {
                setQueryString(searchObj.query)
            }
            if (searchObj.constraints && searchObj.constraints.length > 0) {
                var temp = []
                for (var i = 0; i < searchObj.constraints.length; i++) {
                    var c = searchObj.constraints[i]
                    temp.push({ fieldName: c.fieldName, type: c.type, value: c.value })
                }
                setConstraints(temp)
            }
        }
    }, []);


    const colourStyles = {
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            const color = "#1C3550";
            return {
                ...styles,
                backgroundColor: isSelected
                    ? color
                    : isFocused
                        ? "#1c35504d"
                        : undefined,
                color: isSelected
                    ? "white"
                    : "black",
                ':active': {
                    ...styles[':active'],
                    backgroundColor: isSelected
                        ? color
                        : "#1c35504d"
                    ,
                },
            };
        }
    };

    const [choosenField, setChoosenField] = useState("");
    const [filterType, setFilterType] = useState("");
    const [filterValue, setFilterValue] = useState("");
    const [isFieldSelected, setFieldSelected] = useState(false);


    function changeChoosenField(option, actionMeta) {
        if (actionMeta.action == "clear") {
            setFieldSelected(false);
        }
        else {
            setChoosenField(option.key);
            setFilterValue(''); //qua
            setFieldSelected(true);
        }
    }

    function handleFilterValueChange(event) {
        setFilterValue(event.target.value);
    }

    function changeFilterType(option, actionMeta) {
        if (actionMeta.action != "clear") {
            setFilterType(option);
        }
    }

    function removeConstraint(index) {
        console.log("REMOVE CONSTRAINT ")

        var newConstraints = [...constraints];
        newConstraints.splice(index, 1);
        setConstraints(newConstraints)
    }

    function handleQueryStringChange(event) {
        setQueryString(event.target.value)
    }

    function addFilter() {
        addConstraint(choosenField, filterType.value, filterValue);
        //setFieldSelected(false);
        //setFilterType("")
    }

    function addConstraint(fieldName, type, value) {
        var newConstraints = [...constraints];

        newConstraints.push({
            type: type,
            value: value,
            fieldName: fieldName
        })

        console.log("newConstraints", newConstraints)

        setConstraints(newConstraints)
        setFilterValue("");
    }


    // begin external entity
    const [externalOptions, setExternalOptions] = useState([])
    const [isLoadingExternal, setIsLoadingExternal] = useState(true)

    useEffect(() => {
        if (choosenField) {
            var p = properties.columns[choosenField]
            if (p.input_type === 'externalEntity') {
                setIsLoadingExternal(true)
                var ent = p.options.entity
                var labelField = p.options.labelField
                loadOptions(labelField, ent)
            }
        }
    }, [choosenField]);

    async function loadOptions(labelField, ent) {
        var response = await DataManager.getItems(ent, 1, 1000, {
            'field': 'id',
            'order': 'desc'
        })
        console.warn(response)
        setIsLoadingExternal(false)
        if (response.success == 1) {
            var t = []
            for (let i = 0; i < response.body.length; i++) {
                t.push({ value: response.body[i].id, label: response.body[i][labelField] })
            }
            setExternalOptions(t)
        }
    }

    function handleExternalValueChange(option, actionMeta) {
        if (actionMeta.action == "clear") {
            setFilterValue(false);
        }
        else {
            console.log(option)
            setFilterValue(option.value);
        }
    }
    // end external

    function handleOptionValueChange(option, actionMeta) {
        if (actionMeta.action == "clear") {
            setFilterValue(false);
        }
        else {
            console.log(option)
            var string = ''
            for (var j = 0; j < option.length; j++) {
                if (string.length > 0) {
                    string += ','
                }
                string += option[j].value
            }
            setFilterValue(string);
        }
    }



    useEffect(() => {

        let data = {
            'query': queryString,
            'fields': activeFields,
            'constraints': constraints
        }
        console.log("--- CALLING seachCallback() ---")
        console.log(data)

        const timeOutId = setTimeout(() => setSearchObj(data), 400);
        return () => clearTimeout(timeOutId);

    }, [queryString, activeFields, constraints]);



    //salvataggio filtri
    const [filterGroupName, setFilterGroupName] = useState('')
    const [entityUserFilters, setEntityUserFilters] = useState([])
    async function saveFiltersGroup() {
        if (filterGroupName.length && constraints.length) {
            var body = {
                content: JSON.stringify(constraints),
                name: filterGroupName,
                entity: entity
            }
            var response = await DataManager.insertItem('user_entity_saved_filters', body)
            if (response.success === 1) {
                toast.success('Filtri salvati con successo.', { theme: 'colored' });
                getUserFilters()
            }
            else {
                toast.error('Non è stato possibile salvare i filtri. Controlla di averne inseriti e di aver digitato un nome.', { theme: 'colored' });
            }
        }
        else {
            toast.error('Non è stato possibile salvare i filtri. Controlla di averne inseriti e di aver digitato un nome.', { theme: 'colored' });
        }
    }
    async function getUserFilters() {
        var c = [
            {
                fieldName: 'entity',
                type: 'value',
                value: entity
            }
        ]
        var response = await DataManager.searchItems('user_entity_saved_filters', 1, 20, { 'field': 'id', 'order': 'ASC' }, '', [], c)
        if (response.success === 1) {
            if (response.body && response.body.length) {
                setEntityUserFilters(response.body)
            }
        }
    }
    function applyFilters(item) {
        setConstraints(JSON.parse(item.content))
    }
    useEffect(() => {
        getUserFilters()
    }, []);



    return (
        <>
            <Container fluid className='mt-1 mb-1' style={{ paddingLeft: 0, paddingRight: 0 }}>
                <InputGroup className='search-adv'>
                    <Dropdown>
                        <Dropdown.Toggle variant="primary">
                            {LanguageProvider.get('search.filters')}
                            {constraints && constraints.length > 0 ? (
                                <Badge bg="danger" className='ms-2'>{constraints.length}</Badge>
                            ) : (
                                <></>
                            )}
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            <Dropdown.Header>
                                <Row>
                                    <Col xs={12} md={6}>
                                        {LanguageProvider.get('search.add_filter')}
                                        <p className='small-description-text'>{LanguageProvider.get('search.add_filter_subtitle')}</p>

                                        <span className='small'>{LanguageProvider.get('search.field_label')}</span>
                                        <Select onChange={changeChoosenField} className="basic-single" classNamePrefix="select" defaultValue={""}
                                            isClearable={false} isSearchable={true} styles={colourStyles} options={listOfUsableFilter} />

                                        <span className='small'>{LanguageProvider.get('search.type_label')}</span>
                                        {(() => {
                                            if (typeof (properties.columns[choosenField]) !== 'undefined') {
                                                switch (properties.columns[choosenField].input_type) {
                                                    case 'string': return (
                                                        <Select className="basic-single" onChange={changeFilterType} classNamePrefix="select" defaultValue={""} value={filterType}
                                                            isClearable={true} isSearchable={true} styles={colourStyles} options={usableFiltersConditionsStrings} />
                                                    );
                                                    case 'text': return (
                                                        <Select className="basic-single" onChange={changeFilterType} classNamePrefix="select" defaultValue={""} value={filterType}
                                                            isClearable={true} isSearchable={true} styles={colourStyles} options={usableFiltersConditionsStrings} />
                                                    );
                                                    case 'float': return (
                                                        <Select className="basic-single" onChange={changeFilterType} classNamePrefix="select" defaultValue={""} value={filterType}
                                                            isClearable={true} isSearchable={true} styles={colourStyles} options={usableFiltersConditionsNumbers} />
                                                    );
                                                    case 'number': return (
                                                        <Select className="basic-single" onChange={changeFilterType} classNamePrefix="select" defaultValue={""} value={filterType}
                                                            isClearable={true} isSearchable={true} styles={colourStyles} options={usableFiltersConditionsNumbers} />
                                                    );
                                                    case 'date': return (
                                                        <Select className="basic-single" onChange={changeFilterType} classNamePrefix="select" defaultValue={""} value={filterType}
                                                            isClearable={true} isSearchable={true} styles={colourStyles} options={usableFiltersConditionsDates} />
                                                    );
                                                    case 'time': return (
                                                        <Select className="basic-single" onChange={changeFilterType} classNamePrefix="select" defaultValue={""} value={filterType}
                                                            isClearable={true} isSearchable={true} styles={colourStyles} options={usableFiltersConditionsDates} />
                                                    );
                                                    case 'datetime': return (
                                                        <Select className="basic-single" onChange={changeFilterType} classNamePrefix="select" defaultValue={""} value={filterType}
                                                            isClearable={true} isSearchable={true} styles={colourStyles} options={usableFiltersConditionsDates} />
                                                    );
                                                    case 'boolean': return (
                                                        <Select className="basic-single" onChange={changeFilterType} classNamePrefix="select" defaultValue={""} value={filterType}
                                                            isClearable={true} isSearchable={true} styles={colourStyles} options={usableFiltersConditions} />
                                                    );
                                                    case 'externalEntity': return (
                                                        <Select className="basic-single" onChange={changeFilterType} classNamePrefix="select" defaultValue={""} value={filterType}
                                                            isClearable={true} isSearchable={true} styles={colourStyles} options={usableFiltersConditions} />
                                                    );
                                                    case 'option': return (
                                                        <Select className="basic-single" onChange={changeFilterType} classNamePrefix="select" defaultValue={""} value={filterType}
                                                            isClearable={true} isSearchable={true} styles={colourStyles} options={usableFiltersConditionsOptions} />
                                                    );
                                                    default: return (
                                                        <Select className="basic-single" onChange={changeFilterType} classNamePrefix="select" defaultValue={""} value={filterType}
                                                            isClearable={true} isSearchable={true} styles={colourStyles} options={usableFiltersConditions} />
                                                    );
                                                }
                                            }
                                            else {
                                                return (
                                                    <Select className="basic-single" onChange={changeFilterType} classNamePrefix="select" defaultValue={""} value={filterType}
                                                        isClearable={true} isSearchable={true} styles={colourStyles} options={usableFiltersConditions} />
                                                )
                                            }
                                        })()}

                                        <span className='small'>{LanguageProvider.get('search.field_value')}</span>
                                        {isFieldSelected ?
                                            <div className="mb-3">

                                                {(() => {
                                                    if (typeof (properties.columns[choosenField]) !== 'undefined') {
                                                        switch (properties.columns[choosenField].input_type) {
                                                            case 'string': return (
                                                                <Form.Control value={filterValue} onChange={handleFilterValueChange} type="text" />
                                                            );
                                                            case 'text': return (
                                                                <Form.Control value={filterValue} onChange={handleFilterValueChange} type="text" />
                                                            );
                                                            case 'float': return (
                                                                <Form.Control type="number" value={filterValue} onChange={handleFilterValueChange} />
                                                            );
                                                            case 'number': return (
                                                                <Form.Control type="number" value={filterValue} onChange={handleFilterValueChange} />
                                                            );
                                                            case 'date': return (
                                                                <Form.Control
                                                                    type="date"
                                                                    value={filterValue}
                                                                    onChange={handleFilterValueChange}
                                                                />
                                                            );
                                                            case 'time': return (
                                                                <Form.Control
                                                                    type="time"
                                                                    value={filterValue}
                                                                    onChange={handleFilterValueChange}
                                                                />
                                                            );
                                                            case 'datetime': return (
                                                                <Form.Control
                                                                    type="datetime-local"
                                                                    value={filterValue}
                                                                    onChange={handleFilterValueChange}
                                                                />
                                                            );
                                                            case 'boolean': return (
                                                                <Form.Select onChange={handleFilterValueChange}>
                                                                    <option value='null'>Seleziona</option>
                                                                    <option value="1">{LanguageProvider.get('defaults.true')}</option>
                                                                    <option value="0">{LanguageProvider.get('defaults.false')}</option>
                                                                </Form.Select>
                                                            );
                                                            case 'externalEntity': return (
                                                                <>
                                                                    <Select className="basic-single" onChange={handleExternalValueChange} classNamePrefix="select" defaultValue={""}
                                                                        isLoading={isLoadingExternal} isClearable={true} isSearchable={true} styles={colourStyles} options={externalOptions} />
                                                                </>
                                                            );
                                                            case 'option': {
                                                                var optionSelect = []
                                                                for (var j = 0; j < properties.columns[choosenField].options.options.length; j++) {
                                                                    var option = properties.columns[choosenField].options.options[j]
                                                                    var optionVPkey = choosenField + '.options.' + option.value
                                                                    optionSelect.push({
                                                                        value: option.value,
                                                                        label: LanguageProvider.getLabel(entity, optionVPkey)
                                                                    })
                                                                }
                                                                return (
                                                                    <Select className="basic-multi-select" isMulti onChange={handleOptionValueChange} classNamePrefix="select" defaultValue={""}
                                                                        isClearable={true} isSearchable={true} styles={colourStyles} options={optionSelect} />
                                                                )
                                                            };
                                                            default: return (
                                                                <Form.Control value={filterValue} onChange={handleFilterValueChange} type="text" />
                                                            );
                                                        }
                                                    }
                                                })()}

                                            </div>
                                            :
                                            <p className='medium-select-description-text'>{LanguageProvider.get('search.select_constraint_field')}</p>
                                        }

                                        <Button size="sm" disabled={!filterValue || filterValue.length == 0 || filterType.length == 0} onClick={addFilter} variant="primary">
                                            {LanguageProvider.get('defaults.add')}
                                        </Button>
                                    </Col>

                                    <Col xs={12} md={6}>
                                        {LanguageProvider.get('search.active_filters')}
                                        <p className='small-description-text'>{LanguageProvider.get('search.active_filters_subtitle')}</p>

                                        <div>
                                            {constraints.map((item, index) => {
                                                return (
                                                    <Row key={index} className="mb-3 align-items-center w-100">
                                                        <Col xs={9}>
                                                            <Badge>{LanguageProvider.getLabel(entity, item.fieldName)}</Badge>

                                                            {(() => {
                                                                switch (item.type.toUpperCase()) {
                                                                    case 'IN': {
                                                                        const myArray = item.value.split(",")
                                                                        return (
                                                                            <>
                                                                                <Badge className='me-2 ms-2' bg={'secondary'}>{item.type}</Badge>
                                                                                <span style={{ whiteSpace: 'break-spaces' }}>
                                                                                    {myArray.map((el, index) => {
                                                                                        return (
                                                                                            <Badge bg="info" className='me-1' key={index}>{el}</Badge>
                                                                                        )
                                                                                    })}
                                                                                </span>
                                                                            </>
                                                                        )
                                                                    };
                                                                    case 'LIKE': {
                                                                        return (
                                                                            <>
                                                                                <Badge className='me-2 ms-2' bg={'secondary'}>contiene</Badge>
                                                                                <Badge bg="info">{item.value}</Badge>
                                                                            </>
                                                                        )
                                                                    };
                                                                    case 'VALUE': {
                                                                        return (
                                                                            <>
                                                                                <Badge className='me-2 ms-2' bg={'secondary'}>uguale</Badge>
                                                                                <Badge bg="info">{item.value}</Badge>
                                                                            </>
                                                                        )
                                                                    };
                                                                    case 'DIFFERENT_FROM_VALUE': {
                                                                        return (
                                                                            <>
                                                                                <Badge className='me-2 ms-2' bg={'secondary'}>diverso</Badge>
                                                                                <Badge bg="info">{item.value}</Badge>
                                                                            </>
                                                                        )
                                                                    };
                                                                    case 'MINOR': {
                                                                        return (
                                                                            <>
                                                                                <Badge className='me-2 ms-2' bg={'secondary'}>minore</Badge>
                                                                                <Badge bg="info">{item.value}</Badge>
                                                                            </>
                                                                        )
                                                                    };
                                                                    case 'GREATER': {
                                                                        return (
                                                                            <>
                                                                                <Badge className='me-2 ms-2' bg={'secondary'}>maggiore</Badge>
                                                                                <Badge bg="info">{item.value}</Badge>
                                                                            </>
                                                                        )
                                                                    };
                                                                    default: return (
                                                                        <>
                                                                            <Badge className='me-2 ms-2' bg={'secondary'}>{item.type}</Badge>
                                                                            <Badge bg="info">{item.value}</Badge>
                                                                        </>

                                                                    );
                                                                }
                                                            })()}
                                                        </Col>
                                                        <Col xs={3}>
                                                            <Badge pill bg="danger" role="button" onClick={() => { removeConstraint(index) }}>
                                                                <FontAwesomeIcon icon="xmark" size={'lg'} />
                                                            </Badge>
                                                        </Col>
                                                    </Row>
                                                );
                                            })}
                                        </div>

                                        {constraints && constraints.length > 0 ? (
                                            <InputGroup className="mt-5 mb-3" size={'sm'}>
                                                <Form.Control placeholder="Etichetta gruppo filtri" value={filterGroupName} onChange={(e) => setFilterGroupName(e.target.value)} type="text" />
                                                <Button variant="outline-secondary" onClick={() => saveFiltersGroup()}>
                                                    Salva filtri
                                                </Button>
                                            </InputGroup>
                                        ) : (
                                            <></>
                                        )}

                                        {entityUserFilters && entityUserFilters.length > 0 ? (
                                            <div className='mt-5'>
                                                Filtri salvati<br />
                                                {entityUserFilters.map((item, index) => {
                                                    return (
                                                        <Badge key={index} onClick={() => applyFilters(item)} className="me-2 mb-2 mt-2" role='button'>
                                                            {item.name} <FontAwesomeIcon icon="arrow-right" />
                                                        </Badge>
                                                    );
                                                })}
                                            </div>
                                        ) : (
                                            <></>
                                        )}

                                    </Col>
                                </Row>
                            </Dropdown.Header>
                        </Dropdown.Menu>
                    </Dropdown>
                    
                    {autoFocus === true ? (
                        <Form.Control
                            autoFocus className='border-start-0 search-pro-input' value={queryString} onChange={handleQueryStringChange} type="text" placeholder={LanguageProvider.get('defaults.search')}
                        />
                    ) : (
                        <Form.Control
                            className='border-start-0 search-pro-input' value={queryString} onChange={handleQueryStringChange} type="text" placeholder={LanguageProvider.get('defaults.search')}
                        />
                    )}
                </InputGroup>

                {entityUserFilters && entityUserFilters.length > 0 ? (
                    <div>
                        {entityUserFilters.map((item, index) => {
                            return (
                                <Badge key={index} onClick={() => applyFilters(item)} className="me-2 mb-2 mt-2" role='button'>
                                    {item.name} <FontAwesomeIcon icon="arrow-right" />
                                </Badge>
                            );
                        })}
                    </div>
                ) : (
                    <></>
                )}

            </Container>
        </>
    );
};