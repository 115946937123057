import logo from './logo.svg';

export const settings = {
    "title": "Admin Marco Cekada",
    "apiUrl": "https://api.marcocekada.com/api",
    "ckdPath": "https://api.marcocekada.com/",
    "tinyKey": "u2y9r6cw0e1erg33drzg4fs0swwi1zrs85bcin3ynrsxlwz2",
    "googleMapKey": "AIzaSyB0JItfx2zvDnKkmxIBt_wru36tOVcx1iM",
    "config": {
        "limit": 100,
        "sortingField": 'id',
        "sortingOrder": 'desc'
    },
    "theme": {
        "siderbarIconColor": 'rgba(233,236,239,.5)'
    },
    "logo": logo,
    "loginSocial": {
        "google_client_id": '990335760719-ravsndo1g682t1euf1uvosl8dii6ers7.apps.googleusercontent.com',
        "facebook_app_id": '1281637262413517'
    }
}